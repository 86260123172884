export const LOCAL_STORAGE = {
    TOKEN: 'TOKEN',
    DATA_USER: 'DATA_USER'
}
export const SESSION_STORAGE = {
    DATA_USER: 'DATA_USER'
}

export const actionType = {
    attend: "attend",
    canceled: "canceled"

}

export const deFaultDateChange = {
    morning: [],
    afternoon: [],
    reset: {
        morning: [],
        afternoon: [],
    },
}

export const pathDisableLayout = {
    LOGIN: '/login',
    SINGS: '/sings',
}

export const pathLayoutSetting = {
    EDIT_RICE: '/edit-time-rice',
    EDIT_PLACE: '/edit-place',
    ADD_PLACE: '/add-place',
    SETTING: '/settings',
    PROFILE: '/profile',
    DEPARTMENT: '/edit-department',
    USER_MANAGEMENT: '/user-management',
    STATICAL: '/statical'

}



export const sessionType = {
    morning: "morning",
    afternoon: "afternoon"

}

export const isCheckLogin = (token: string) => {
    return token ? true : false
}

export const getToken = () => {
    return localStorage.getItem(LOCAL_STORAGE.TOKEN) ?? ""
}

export const getRole = (user: any) => {
    return user?.role ?? ""
}

export const getDetailDepartment = (id: any, list: any) => {
    return list?.find((val: any) => val.id === Number(id))
}
export const getDetailPlace = (id: any, list: any) => {
    return list?.find((val: any) => val.id === Number(id))
}



export const getParam: any = (param: string) => {
    const searchParams = new URLSearchParams(window?.location?.search ?? "");
    return String(searchParams.get(param)) ?? "attend"
}




export const getAttended = ( morningAttended:any, afternoonAttended:any) => {
    return afternoonAttended && morningAttended
        ? 'Đã báo cơm sáng + chiều'
        : afternoonAttended
            ? 'Đã báo cơm chiều'
            : morningAttended
                ? 'Đã báo cơm sáng'
                : 'Báo cơm';
};

export const getCancel = ( morningCanceled:any, afternoonCanceled:any) => {
    return afternoonCanceled && morningCanceled
        ? 'Đã cắt cơm sáng + chiều'
        : afternoonCanceled
            ? 'Đã cắt cơm chiều'
            : morningCanceled
                ? 'Đã cắt cơm sáng'
                : 'Cắt cơm';
};


export const getStatusDisableAttend = (status: any) => {
    return status.afternoonAttended || status.morningAttended;
};

export const getStatusDisableCancel = (status: any) => {
    return status.morningCanceled || status.afternoonCanceled;
};