import { ROUTE, ROUTE_PRIVATE } from "../../helpers/route";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setUserInfo } from "stores/useReducer/action";
import { LOCAL_STORAGE, pathLayoutSetting } from "../../helpers/helper";
import { Icon } from "../../helpers/Icon";
import { Roles } from "../../helpers/roles";
import { PlaceType } from "../../helpers/type";
import styles from "./layout.module.scss";
import Menu from "./Menu";

const LayoutAdmin: React.FC<{ Children: React.ReactNode }> = ({ Children }) => {
  const resources = useSelector((state: any) => state?.userReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const [menuEdit, setMenuEdit] = React.useState(false);
  const [titleChangeMenu, setTitleChangeMenu] = React.useState("");

  const menuUAdmin = [
    {
      name: "Trang chủ",
      href: "/",
      icon: <Icon.HomeIcon />,
      event: "",
    },
    {
      name: "Cài đặt",
      href: ROUTE_PRIVATE.SETTING,
      icon: <Icon.SettingIcon />,
      event: "",
    },
    {
      name: "Quản lý",
      href: ROUTE_PRIVATE.USER_MANAGEMENT,
      icon: <Icon.UserEdit />,
      event: "",
    },
    {
      name: "Thống kê",
      href: ROUTE_PRIVATE.STATICAL,
      icon: <Icon.StaticalIcon />,
      event: "",
    },
    {
      name: "Trang cá nhân",
      href: ROUTE.PROFILE,
      icon: <Icon.UserIcon />,
      event: "",
    },
  ];

  React.useEffect(() => {
    if (resources?.user?.role === Roles.ADMIN) {
      if (location?.pathname === pathLayoutSetting.EDIT_RICE) {
        setMenuEdit(true);
        setTitleChangeMenu("Chỉnh sửa thời gian");
        return;
      }
      if (location?.pathname === pathLayoutSetting.EDIT_PLACE) {
        setMenuEdit(true);
        setTitleChangeMenu("Chỉnh sửa địa điểm");
        return;
      }
      if (location?.pathname === pathLayoutSetting.SETTING) {
        setMenuEdit(true);
        setTitleChangeMenu("Cài đặt");
        return;
      }
      if (location?.pathname === pathLayoutSetting.PROFILE) {
        setMenuEdit(true);
        setTitleChangeMenu("Trang cá nhân");
        return;
      }
      if (location?.pathname === pathLayoutSetting.DEPARTMENT) {
        setMenuEdit(true);
        setTitleChangeMenu("Chỉnh sửa phòng ban");
        return;
      }
      if (
        location?.pathname === pathLayoutSetting.USER_MANAGEMENT ||
        pathLayoutSetting.USER_MANAGEMENT ===
          "/" + location?.pathname?.split("/")[1]
      ) {
        setMenuEdit(true);
        setTitleChangeMenu("Quàn lý người dùng");
        return;
      }
      if (
        location?.pathname === pathLayoutSetting.USER_MANAGEMENT ||
        pathLayoutSetting.USER_MANAGEMENT ===
          "/" + location?.pathname?.split("/")[1]
      ) {
        setMenuEdit(true);
        setTitleChangeMenu("Quàn lý người dùng");
        return;
      }
      if (
        location?.pathname === pathLayoutSetting.STATICAL ||
        pathLayoutSetting.STATICAL === "/" + location?.pathname?.split("/")[1]
      ) {
        setMenuEdit(true);
        setTitleChangeMenu("Thống kê");
        return;
      }
      setMenuEdit(false);
    }
  }, [location, resources, params]);

  const setPlaces = (place: any) => {
    let user = { ...resources?.user, place: { ...place } };
    localStorage.setItem(LOCAL_STORAGE.DATA_USER, JSON.stringify(user));
    dispatch(setUserInfo({ user }));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header} style={menuEdit ? { padding: "0px" } : {}}>
        {!menuEdit && (
          <React.Fragment>
            <div className={styles.logo}>
              <Icon.EvnLogo />
            </div>
            <div className={styles.category}>
              {resources?.place?.map((item: PlaceType) => (
                <div
                  key={item.id}
                  onClick={() => setPlaces(item)}
                  className={`${styles.itemPlace} ${
                    item.id === resources?.user?.place.id
                      ? styles.itemPlaceActive
                      : ""
                  }`}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </React.Fragment>
        )}

        {menuEdit && (
          <React.Fragment>
            <div className={styles.changeMenu}>
              <div className={styles.icon} onClick={() => navigate(-1)}>
                <Icon.GobackIcon />
              </div>
              {titleChangeMenu}
            </div>
          </React.Fragment>
        )}
      </div>
      <div
        className="wrapper"
        style={
          resources?.user.role === Roles.ADMIN ? { background: "#F9F9F9" } : {}
        }
      >
        {Children}
      </div>
      <div className={styles.menu}>
        <Menu array={menuUAdmin} />
      </div>
    </div>
  );
};

export default LayoutAdmin;
