import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./layout.module.scss";

export type Item = {
  icon?: React.ReactNode;
  name?: string;
  event?: any;
  href?: string;
};

export type TypeProps = {
  array: Array<Item>;
};

const Menu = (props: TypeProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className={styles.itemMenu}>
      {props?.array?.map((item: Item, index: number) => {
        return (
          <div
            key={index}
            onClick={item.event ? item.event : () => navigate(item?.href ?? "/")}
            className={`${styles.item} ${
              location?.pathname === item?.href ? styles.itemActive : ""
            }`}
          >
            <span>{item.icon}</span>
            <label>{item.name}</label>
          </div>
        );
      })}
    </div>
  );
};

export default Menu;
