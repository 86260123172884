import { Roles } from "../../helpers/roles";
import React from "react";
import LayoutAdmin from "./LayoutAdmin";
import LayoutUser from "./LayoutUser";
import { Spin } from "antd";

type LayoutProps = {
  children: React.ReactNode;
  roles?: string;
  display?: boolean;
  blocking?: boolean;
};

const Layout = (props: LayoutProps) => {
  const renderLayout = () => {
    if (!props.display) {
      if (props.roles === Roles.ADMIN) {
        return <LayoutAdmin Children={props.children} />;
      }
      if (props.roles === Roles.USER) {
        return <LayoutUser Children={props.children} />;
      }
    }

    return <React.Fragment>{props.children}</React.Fragment>;
  };
  return (
    <div style={styles.wrapper}>
      {renderLayout()}
      {props.blocking ? (
        ""
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "fixed",
            cursor: "wait",
            top: "0",
            left: "0",
            background: "white",
            opacity: "0.5",
            zIndex:'9999',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
          }}
        >
          <Spin  />
        </div>
      )}
    </div>
  );
};

const styles = {
  wrapper: {
    width: "100%",
    height: "100%",
  },
  header: {},
  menu: {},
};

export default Layout;
