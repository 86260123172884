import React, { Component } from "react";

export default class PrevIcon extends Component {
  render() {
    return (
      <svg
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 1L7.5 7.5L1 14" stroke="#8D8D8D" strokeLinecap="round" />
      </svg>
    );
  }
}
