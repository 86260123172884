import React, { useState, useEffect } from "react";

const DelayedFallback = () => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    let timeout = setTimeout(() => setShow(false), 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return <>{show && <div style={{width:'100%',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
    <h3>Loading ...</h3>
    </div>}</>;
};
export default DelayedFallback;
