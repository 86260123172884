import { combineReducers, createStore } from 'redux'
import userReducer from './useReducer/reducer';




// NOTE: Redux DevTools Extension を使用したい場合はコメントを外す（本番環境では使えないようにコメントアウトしている）
// interface ExtendedWindow extends Window {
//     __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
// }

// declare var window: ExtendedWindow;

// const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export default createStore(combineReducers<Store>({ sample, ui, auth }), composeEnhancers(applyMiddleware()));

export default createStore(combineReducers<any>({userReducer}));
