import { getToken } from '../../helpers/helper';
import {request} from 'api/request';

export const getUserInfo = (token:string) =>
  request.get('/users/me', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const setMealClosingApi = (params:any) =>
  request.post('/meal-closing', params,{
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const getMealCloseTargetDay = (targetDay:any) => {
  return request.get(`/meal-closing/${targetDay}`);
};
export const getMealCloseTargetMonth = (targetMonth:any) =>
  request.get(`/meal-closing?targetMonth=${targetMonth}`,{
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const updateTokenFCM = (params:any) =>
  request.post(`/users/fcm-token`, params);

export const logoutApi = () => request.post('/users/logout',{
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

export const deleteAccount = () => request.post(`/users/delete`);
