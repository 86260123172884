import React from "react";

const DepartmentIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33338 14.6667H10.6667C13.3467 14.6667 13.8267 13.5933 13.9667 12.2867L14.4667 6.95333C14.6467 5.32667 14.18 4 11.3334 4H4.66671C1.82005 4 1.35338 5.32667 1.53338 6.95333L2.03338 12.2867C2.17338 13.5933 2.65338 14.6667 5.33338 14.6667Z"
        stroke="#595959"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33325 4.00016V3.46683C5.33325 2.28683 5.33325 1.3335 7.46659 1.3335H8.53325C10.6666 1.3335 10.6666 2.28683 10.6666 3.46683V4.00016"
        stroke="#595959"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33341 8.66667V9.33333C9.33341 9.34 9.33341 9.34 9.33341 9.34667C9.33341 10.0733 9.32675 10.6667 8.00008 10.6667C6.68008 10.6667 6.66675 10.08 6.66675 9.35333V8.66667C6.66675 8 6.66675 8 7.33341 8H8.66675C9.33341 8 9.33341 8 9.33341 8.66667Z"
        stroke="#595959"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4333 7.3335C12.8933 8.4535 11.1333 9.12016 9.33325 9.34683"
        stroke="#595959"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.74658 7.51318C3.24658 8.53985 4.93992 9.15985 6.66658 9.35318"
        stroke="#595959"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DepartmentIcon;
