import React from "react";

const Profile = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50 1.5625C22.379 1.5625 0 23.2422 0 50C0 76.7578 22.379 98.4375 50 98.4375C77.621 98.4375 100 76.7578 100 50C100 23.2422 77.621 1.5625 50 1.5625ZM50 20.3125C59.7984 20.3125 67.7419 28.0078 67.7419 37.5C67.7419 46.9922 59.7984 54.6875 50 54.6875C40.2016 54.6875 32.2581 46.9922 32.2581 37.5C32.2581 28.0078 40.2016 20.3125 50 20.3125ZM50 87.5C38.1653 87.5 27.5605 82.3047 20.4637 74.1797C24.254 67.2656 31.6734 62.5 40.3226 62.5C40.8065 62.5 41.2903 62.5781 41.754 62.7148C44.375 63.5352 47.1169 64.0625 50 64.0625C52.8831 64.0625 55.6452 63.5352 58.246 62.7148C58.7097 62.5781 59.1936 62.5 59.6774 62.5C68.3266 62.5 75.746 67.2656 79.5363 74.1797C72.4395 82.3047 61.8347 87.5 50 87.5Z"
        fill="#174F9A"
      />
      <circle cx="50" cy="38" r="18" fill="white" />
    </svg>
  );
};

export default Profile;
