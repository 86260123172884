import { Icon } from "../../helpers/Icon";
import React from "react";
import Menu from "./Menu";
import styles from "./layout.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { PlaceType } from "../../helpers/type";
import { openModalPlace, setUserInfo } from "stores/useReducer/action";
import { LOCAL_STORAGE } from "../../helpers/helper";
import { useLocation } from "react-router-dom";
import { ROUTE } from "../../helpers/route";

type LayoutProps = {
  Children: React.ReactNode;
};

const LayoutUser = ({ Children }: LayoutProps) => {
  const dispatch = useDispatch();
  const resources = useSelector((state: any) => state?.userReducer);
  const location = useLocation()


  const menuUser = [
    {
      name: "Trang chủ",
      href: "/",
      icon: <Icon.HomeIcon />,
      event: "",
    },
    {
      name: "Cắt/Báo cơm",
      href: ROUTE.RICE_DOT_BOARD,
      icon: <Icon.DateIcon />,
      event:
       (location?.pathname === "/rice-dot-board" || location?.pathname === "/profile")
          ? ""
          : () => dispatch(openModalPlace(true,'')),
    },
    {
      name: "Trang cá nhân",
      href: ROUTE.PROFILE,
      icon: <Icon.UserIcon />,
      event: "",
    },
  ];

  const setPlaces = (place: any) => {
    let user = { ...resources?.user, place: { ...place } };
    localStorage.setItem(LOCAL_STORAGE.DATA_USER, JSON.stringify(user));
    dispatch(setUserInfo({ user }));
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <Icon.LogoEvnHeader />
        </div>
        <div className={styles.category}>
          {resources?.place?.map((item: PlaceType) => (
            <div
              onClick={() => setPlaces(item)}
              key={item.id}
              className={`${styles.itemPlace} ${
                item.id === resources?.user?.place.id
                  ? styles.itemPlaceActive
                  : ""
              }`}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
      <div className="wrapper">{Children}</div>
      <div className={styles.menu}>
        <Menu array={menuUser} />
      </div>
    </div>
  );
};

export default LayoutUser;
