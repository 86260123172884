import ActionType from './type';

const initState = {};

const userReducer = (state = initState, action: any) => {
  switch (action.type) {
    case ActionType.SET_USER:
    case ActionType.DEPARTMENT:
    case ActionType.PLACE:
    case ActionType.BANNER:
    case ActionType.CHOOSE_PLACE:
    case ActionType.ACTION_TYPE:
    case ActionType.TIME_LOADING:
    case ActionType.DATE_STATICAL:
      return {
        ...state,
        ...action.payload,
        modalPlace: {
          action: action.payload.modalPlace?.action ? action.payload.modalPlace?.action : false,
          href: action.payload.modalPlace?.href ? action.payload.modalPlace?.href : ""
        },
        loading: action.payload.loading ? action.payload.loading : false
      }

    case ActionType.LOGOUT:
      return {};
    default:
      return state;
  }
};

export default userReducer;
