import  PasswordIcon  from '../assets/icon/PasswordIcon';
import  UserIcon  from '../assets/icon/UserIcon';
import EvnLogo from "../assets/icon/EvnLogo";
import HomeIcon from '../assets/icon/HomeIcon';
import DateIcon from '../assets/icon/DateIcon';
import LogoEvnHeader from '../assets/icon/LogoEvnHeader';
import DateIconV2 from '../assets/icon/DateIconV2';
import ClockIcon from '../assets/icon/ClockIcon';
import GobackIcon from '../assets/icon/IconGoback';
import PrevIcon from '../assets/icon/PrevIcon';
import SettingIcon from '../assets/icon/SettingIcon';
import StaticalIcon from '../assets/icon/StaticalIcon';
import UserEdit from  '../assets/icon/UserEdit';
import FilterIcon from '../assets/icon/FilterIcon';
import PhoneIcon from '../assets/icon/PhoneIcon';
import EmailIcon from '../assets/icon/EmailIcon';
import RoleIcon from '../assets/icon/RoleIcon';
import PlaceIcon from '../assets/icon/PlaceIcon';
import IconChange from '../assets/icon/IconChange';
import DepartmentIcon from '../assets/icon/DepartmentIcon';
import EvnLogo2 from '../assets/icon/EvnLogo2';
import Profile from '../assets/icon/Profile';
export const Icon = {
    EvnLogo,UserIcon,PasswordIcon,HomeIcon,DateIcon,LogoEvnHeader,DateIconV2,ClockIcon,GobackIcon,PrevIcon,SettingIcon,StaticalIcon,UserEdit,FilterIcon,PhoneIcon,
    EmailIcon,RoleIcon,PlaceIcon,IconChange,DepartmentIcon,EvnLogo2,Profile
}