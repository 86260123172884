import React from "react";
import { useSelector } from "react-redux";

export const DelayLoading = () => {
  const loading = useSelector((state: any) => state.userReducer.loading);
  const [show, setShow] = React.useState(loading);
  React.useEffect(() => {
    let timeout = setTimeout(() => setShow(true), 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return show;
};
