import {
  getBanners,
  getDepartmentsApi,
  getPlacesApi
} from "api/model/resource";
import { getSettingApi } from "api/model/setting";
import { getUserInfo } from "api/model/user";
import DelayedFallback from "component/DelayFallBack";
import Layout from "component/layout";
import ModalPlace from "component/ModalPlace";
import { DelayLoading } from "DelayLoading";
import PrivateRoute from "PrivateRoute";
import React, { lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  clearLoading,
  setDataBanner,
  setDataDepartments,
  setDataPlaces,
  setDataSetting,
  setUserInfo
} from "stores/useReducer/action";
import "./App.css";
import { LOCAL_STORAGE, pathDisableLayout } from "./helpers/helper";

const Sings = lazy(() => import("./screen/account/Sings"));
const Login = lazy(() => import("./screen/account/Login"));

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [display,setDisplay] = React.useState(false);
  const [role, setRole] = React.useState("");
  const location = useLocation();
  const modalPlaced = useSelector((state:any) => state.userReducer.modalPlace)

  const delayApp = DelayLoading()

  React.useEffect(() => {
    const fetchApi = async () => {
      let resPlace: any = await getPlacesApi();
      let resDepartment: any = await getDepartmentsApi();
      let resBanner: any = await getBanners();
      let resSetting = await getSettingApi();
      dispatch(setDataPlaces({ place: resPlace }));
      dispatch(setDataDepartments({ department: resDepartment }));
      dispatch(setDataBanner({ banner: resBanner }));
      dispatch(setDataSetting({ setting: resSetting }));
    };
    fetchApi();
  }, [dispatch]);

  //check user permissions
  React.useEffect(() => {
    let token = localStorage.getItem(LOCAL_STORAGE.TOKEN) ?? "";
    const fetchUser = async () => {
      return await getUserInfo(token);
    };
    if (token) {
      fetchUser()
        .then((data: any) => {
          let dataUser:any = data;
          setRole(data?.role);
          if(localStorage.getItem(LOCAL_STORAGE.DATA_USER)){
            dataUser = JSON.parse(String(localStorage.getItem(LOCAL_STORAGE.DATA_USER)))
          }
          if(data){
            dispatch(setUserInfo({ user: dataUser }));
          }else{
            localStorage.clear();
            navigate("/login");
          }

        })
        .catch((err: any) => {
          localStorage.clear();
          navigate("/login");
        });
    } else {
      if(location?.pathname === pathDisableLayout.LOGIN || location?.pathname === pathDisableLayout.SINGS){
        return;
      }
      navigate("/login");
    }
  }, [dispatch, navigate,location]);

  React.useEffect(()=>{
    setDisplay(false);
    if(location.pathname){
      if(location.pathname === pathDisableLayout.LOGIN ||
        location.pathname === pathDisableLayout.SINGS
        ){
        setDisplay(true);
      }
    }
  },[location])


React.useEffect(()=>{
  if(delayApp){
    dispatch(clearLoading(false))
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[delayApp,location?.pathname])


  return (
    <div className="container">
      <Layout blocking={delayApp} roles={role} display={display} >
        <React.Fragment>
          <React.Suspense
            fallback={
              <React.Fragment>
                <DelayedFallback />
              </React.Fragment>
            }
          >
            <Routes>
              {PrivateRoute({ role: role })?.map(
                (route: any, index: number) => (
                  <Route
                    key={index}
                    path={route.path}
                    Component={route.component}
                  />
                )
              )}
              <Route path="/login" Component={Login} />
              <Route path="/sings" Component={Sings} />
            </Routes>
          </React.Suspense>
        </React.Fragment>
      </Layout>
      <ToastContainer />
      <ModalPlace open={modalPlaced?.action} />
    </div>
  );
}

export default App;
