import { getToken } from '../../helpers/helper';
import {request} from 'api/request';

export const getSettingApi = () => request.get('/time-setting',{
  headers: {
        Authorization: `Bearer ${getToken()}`,
}
});

export const setSettingApi = (params:any) => request.post('/time-setting', params,{
    headers: {
          Authorization: `Bearer ${getToken()}`,
  }
  });
