import React from "react";
import { Roles } from "./helpers/roles";
import { ROUTE, ROUTE_PRIVATE } from "./helpers/route";

const TableMeal = React.lazy(() => import("./screen/board/TableMeal"));
const Home = React.lazy(() => import("./screen/home"));
const Error = React.lazy(() => import("./component/Error"));
const EditTimeRice = React.lazy(() => import("./screen/admin/EditTimeRice"));
const EditPlace = React.lazy(() => import("./screen/admin/EditPlace"));
const Setting = React.lazy(() => import("./screen/admin/Setting"));
const UserManagement = React.lazy(
  () => import("./screen/admin/UserManagement")
);
const Profile = React.lazy(() => import("./screen/profile/Profile"));
const EditDepartment = React.lazy(
  () => import("./screen/admin/EditDepartment")
);
const EditProfile = React.lazy(() => import("./screen/admin/EditProfile"));
const Statical = React.lazy(() => import("./screen/admin/statical/Statical"));
const DetailStatical = React.lazy(
  () => import("./screen/admin/statical/DetailStatical")
);
const DetailStaticalRice = React.lazy(
  () => import("./screen/admin/statical/StaticalRice")
);




const PrivateRoute = ({ role = "" }: { role: string }) => {
  const privateRoute = [
    {
      path: "/",
      component: Home,
      permission: [Roles.ADMIN, Roles.USER],
    },
    {
      path: ROUTE.RICE_DOT_BOARD,
      component: TableMeal,
      permission: [Roles.USER],
    },
    {
      path: ROUTE.PROFILE,
      component: Profile,
      permission: [Roles.USER, Roles.ADMIN],
    },
    {
      path: ROUTE_PRIVATE.SETTING,
      component: Setting,
      permission: [Roles.ADMIN],
    },
    {
      path: ROUTE_PRIVATE.MANAGEMENT,
      component: UserManagement,
      permission: [Roles.ADMIN],
    },
    {
      path: ROUTE_PRIVATE.EDIT_TIME_RICE,
      component: EditTimeRice,
      permission: [Roles.ADMIN],
    },
    {
      path: ROUTE_PRIVATE.EDIT_PLACE,
      component: EditPlace,
      permission: [Roles.ADMIN],
    },
    {
      path: ROUTE_PRIVATE.EDIT_DEPARTMENT,
      component: EditDepartment,
      permission: [Roles.ADMIN],
    },
    {
      path: ROUTE_PRIVATE.USER_MANAGEMENT,
      component: UserManagement,
      permission: [Roles.ADMIN],
    },
    {
      path: ROUTE_PRIVATE.USER_MANAGEMENT_ID,
      component: EditProfile,
      permission: [Roles.ADMIN],
    },
    {
      path: ROUTE_PRIVATE.STATICAL,
      component: Statical,
      permission: [Roles.ADMIN],
    },
    {
      path: ROUTE_PRIVATE.STATICAL_ID,
      component: DetailStatical,
      permission: [Roles.ADMIN],
    },
    {
      path: ROUTE_PRIVATE.STATICAL_RICE,
      component: DetailStaticalRice,
      permission: [Roles.ADMIN],
    },
    {
      path: "*",
      component: Error,
      permission: [Roles.USER, Roles.ADMIN],
    },
  ];

  return privateRoute?.filter((route) => route?.permission?.includes(role));
};

export default PrivateRoute;
