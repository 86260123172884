import React from "react";

const EmailIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6666 7.00016V10.3335C14.6666 12.6668 13.3333 13.6668 11.3333 13.6668H4.66659C2.66659 13.6668 1.33325 12.6668 1.33325 10.3335V5.66683C1.33325 3.3335 2.66659 2.3335 4.66659 2.3335H9.33325"
        stroke="#595959"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66675 6L6.75342 7.66667C7.44008 8.21333 8.56675 8.21333 9.25342 7.66667L10.0401 7.04"
        stroke="#595959"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9999 5.33333C13.9204 5.33333 14.6666 4.58714 14.6666 3.66667C14.6666 2.74619 13.9204 2 12.9999 2C12.0794 2 11.3333 2.74619 11.3333 3.66667C11.3333 4.58714 12.0794 5.33333 12.9999 5.33333Z"
        stroke="#595959"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmailIcon;
