import { getToken } from '../../helpers/helper';
import {request} from '../request';

export const getPlacesApi = () => request.get('/places');

export const getDepartmentsApi = () => request.get('/departments',{
  headers: {
        Authorization: `Bearer ${getToken()}`,
}
});

export const setDepartments = (params:any) => request.post('/departments', params,{
  headers: {
        Authorization: `Bearer ${getToken()}`,
}
});

export const setPlaces = (params:any) => request.post('/places', params,{
  headers: {
        Authorization: `Bearer ${getToken()}`,
}
});

export const updateDepartmentsApi = (params:any) =>
  request.patch(`/departments/${params?.id}`, params,{
    headers: {
          Authorization: `Bearer ${getToken()}`,
  }
  });

export const updatePlaceApi = (params:any) =>
  request.patch(`/places/${params?.id}`, params,{
    headers: {
          Authorization: `Bearer ${getToken()}`,
  }
  });

export const getBanners = () => request.get('/banners');


