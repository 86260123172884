import React from "react";

const FilterIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.40002 2.1001H18.6C19.7 2.1001 20.6 3.0001 20.6 4.1001V6.3001C20.6 7.1001 20.1 8.1001 19.6 8.6001L15.3 12.4001C14.7 12.9001 14.3 13.9001 14.3 14.7001V19.0001C14.3 19.6001 13.9 20.4001 13.4 20.7001L12 21.6001C10.7 22.4001 8.90002 21.5001 8.90002 19.9001V14.6001C8.90002 13.9001 8.50002 13.0001 8.10002 12.5001L4.30002 8.5001C3.80002 8.0001 3.40002 7.1001 3.40002 6.5001V4.2001C3.40002 3.0001 4.30002 2.1001 5.40002 2.1001Z"
        stroke="#595959"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.93 2.1001L6 10.0001"
        stroke="#595959"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterIcon;
