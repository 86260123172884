export const ROUTE = {
    RICE_DOT_BOARD:'/rice-dot-board',
    PROFILE: '/profile',
    
}

export const ROUTE_PUBLIC = {
    LOGIN:'/login',
    SINGS: '/sings',
}

export const ROUTE_PRIVATE = {
    SETTING: '/settings',
    MANAGEMENT: '/management',
    EDIT_PLACE: '/edit-place',
    EDIT_DEPARTMENT: '/edit-department',
    USER_MANAGEMENT: '/user-management',
    USER_MANAGEMENT_ID: '/user-management/:id',
    STATICAL: '/statical',
    STATICAL_ID: '/statical/:id',
    STATICAL_RICE: '/statical/rice',
    EDIT_TIME_RICE: '/edit-time-rice',
}

