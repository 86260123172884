import { getToken } from "../helpers/helper";
import axios from "axios";


export const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://api-evn-meal-atd.senkutech.com/",
  timeout: 8000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  },
});

request.interceptors.response.use(
  (response: any) => {
    return response.data;
  },
  async (error: any) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const { response } = error || {};
    const { data } = response || {};
    const { message, status } = data || {};
    if (status === 401 || response?.status === 401) {
      // const accessToken = store.getState()?.userReducer?.accessToken;

      return Promise.reject({
        message: 'Token_Expired',
      });
    }
    if (message === 'RefreshToken_NotExist') {
      // logout here

      return Promise.reject(error);
    }
    if (error.message === 'Network Error') {
      return Promise.reject({
        message: 'Network Error',
      });
    }

    error.message = message;
    error.code = status || '';
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ message: error.message, code: error.code });
  },
);

