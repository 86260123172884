import React from "react";

const PhoneIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6466 12.2202C14.6466 12.4602 14.5933 12.7068 14.4799 12.9468C14.3666 13.1868 14.2199 13.4135 14.0266 13.6268C13.6999 13.9868 13.3399 14.2468 12.9333 14.4135C12.5333 14.5802 12.0999 14.6668 11.6333 14.6668C10.9533 14.6668 10.2266 14.5068 9.45992 14.1802C8.69325 13.8535 7.92658 13.4135 7.16658 12.8602C6.39992 12.3002 5.67325 11.6802 4.97992 10.9935C4.29325 10.3002 3.67325 9.5735 3.11992 8.8135C2.57325 8.0535 2.13325 7.2935 1.81325 6.54016C1.49325 5.78016 1.33325 5.0535 1.33325 4.36016C1.33325 3.90683 1.41325 3.4735 1.57325 3.0735C1.73325 2.66683 1.98659 2.2935 2.33992 1.96016C2.76659 1.54016 3.23325 1.3335 3.72659 1.3335C3.91325 1.3335 4.09992 1.3735 4.26659 1.4535C4.43992 1.5335 4.59325 1.6535 4.71325 1.82683L6.25992 4.00683C6.37992 4.1735 6.46658 4.32683 6.52658 4.4735C6.58658 4.6135 6.61992 4.7535 6.61992 4.88016C6.61992 5.04016 6.57325 5.20016 6.47992 5.3535C6.39325 5.50683 6.26658 5.66683 6.10658 5.82683L5.59992 6.3535C5.52658 6.42683 5.49325 6.5135 5.49325 6.62016C5.49325 6.6735 5.49992 6.72016 5.51325 6.7735C5.53325 6.82683 5.55325 6.86683 5.56659 6.90683C5.68659 7.12683 5.89325 7.4135 6.18658 7.76016C6.48658 8.10683 6.80658 8.46016 7.15325 8.8135C7.51325 9.16683 7.85992 9.4935 8.21325 9.7935C8.55992 10.0868 8.84658 10.2868 9.07325 10.4068C9.10658 10.4202 9.14658 10.4402 9.19325 10.4602C9.24658 10.4802 9.29992 10.4868 9.35992 10.4868C9.47325 10.4868 9.55992 10.4468 9.63325 10.3735L10.1399 9.8735C10.3066 9.70683 10.4666 9.58016 10.6199 9.50016C10.7733 9.40683 10.9266 9.36016 11.0933 9.36016C11.2199 9.36016 11.3533 9.38683 11.4999 9.44683C11.6466 9.50683 11.7999 9.5935 11.9666 9.70683L14.1733 11.2735C14.3466 11.3935 14.4666 11.5335 14.5399 11.7002C14.6066 11.8668 14.6466 12.0335 14.6466 12.2202Z"
        stroke="#595959"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default PhoneIcon;
