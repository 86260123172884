import React from 'react';

const ClockIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.3333 9.99984C18.3333 14.5998 14.6 18.3332 9.99999 18.3332C5.39999 18.3332 1.66666 14.5998 1.66666 9.99984C1.66666 5.39984 5.39999 1.6665 9.99999 1.6665C14.6 1.6665 18.3333 5.39984 18.3333 9.99984Z" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.0917 12.65L10.5083 11.1083C10.0583 10.8416 9.69168 10.2 9.69168 9.67497V6.2583" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    );
};

export default ClockIcon;