import React from "react";

const IconChange = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.62993 1.80011L2.52493 6.14511C2.36993 6.31011 2.21993 6.63511 2.18993 6.86011L2.00493 8.48011C1.93993 9.06511 2.35993 9.46511 2.93993 9.36511L4.54993 9.09011C4.77493 9.05011 5.08993 8.88511 5.24493 8.71511L9.34993 4.37011C10.0599 3.62011 10.3799 2.76511 9.27493 1.72011C8.17493 0.685108 7.33993 1.05011 6.62993 1.80011Z"
        stroke="#595959"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.94507 2.5249C6.16007 3.9049 7.28007 4.9599 8.67007 5.0999"
        stroke="#595959"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 11H10.5"
        stroke="#595959"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconChange;
