import { getDetailPlace, LOCAL_STORAGE } from '../helpers/helper';
import { Modal } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { openModalPlace, setUserInfo } from 'stores/useReducer/action';
import './modal.scss';
import { ROUTE } from '../helpers/route';


type TypeModalPlace = {
  open?: boolean;
  close?: boolean;
  onChange?:any
}


const ModalPlace = ({open,close,...rest}:TypeModalPlace) => {

    const resources = useSelector((state:any) => state?.userReducer)
    const modalPlace = useSelector((state: any) => state?.userReducer?.modalPlace)
    const dispatch = useDispatch();
  const navigate = useNavigate()
    const onClickPlace = (id:any) => {
        let place = getDetailPlace(id,resources?.place)
        let user = {...resources?.user,place:{...place}}
        localStorage.setItem(LOCAL_STORAGE.DATA_USER,JSON.stringify(user))
        dispatch(setUserInfo({user}))
        if(modalPlace.href){
          navigate(modalPlace.href)
        }else{
          navigate(ROUTE.RICE_DOT_BOARD)
        }
      
    }

    return (
      <React.Fragment>
      
        <Modal  centered className='modal-place' {...rest} onCancel={()=>dispatch(openModalPlace(false,''))} closeIcon={true}  open={open}    footer={[]}>
          <div className='place-content'>
            <img src='/plcae.png'  alt='' />
            <h3>Bạn đang ở bếp nào?</h3>
            <div className='list-place'>
              {
                resources?.place?.map((item:any,index:number) => (
                  <div onClick={()=>onClickPlace(item?.id)} key={index} className={`item-place ${item?.id === resources?.user?.place?.id ? 'item-active' : ""}`}>
                      {item.name}
                  </div>
                ))
              }
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  };

export default ModalPlace;