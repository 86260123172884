const ActionType = {
  SET_USER: 'SET_USER',
  LOGOUT: 'LOGOUT',
  DEPARTMENT: 'DEPARTMENT',
  PLACE: 'PLACE',
  BANNER: 'BANNER',
  CHOOSE_PLACE: 'CHOOSE_PLACE',
  ACTION_TYPE: 'ACTION_TYPE',
  TIME_LOADING: 'TIME_LOADING',
  DATE_STATICAL: 'DATE_STATICAL'
};

export default ActionType;
