import React from "react";

const RoleIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.10671 7.24683C6.04004 7.24016 5.96004 7.24016 5.88671 7.24683C4.30004 7.1935 3.04004 5.8935 3.04004 4.2935C3.04004 2.66016 4.36004 1.3335 6.00004 1.3335C7.63337 1.3335 8.96004 2.66016 8.96004 4.2935C8.95337 5.8935 7.69337 7.1935 6.10671 7.24683Z"
        stroke="#595959"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9399 2.6665C12.2333 2.6665 13.2733 3.71317 13.2733 4.99984C13.2733 6.25984 12.2733 7.2865 11.0266 7.33317C10.9733 7.3265 10.9133 7.3265 10.8533 7.33317"
        stroke="#595959"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.77323 9.7065C1.1599 10.7865 1.1599 12.5465 2.77323 13.6198C4.60657 14.8465 7.61323 14.8465 9.44657 13.6198C11.0599 12.5398 11.0599 10.7798 9.44657 9.7065C7.6199 8.4865 4.61323 8.4865 2.77323 9.7065Z"
        stroke="#595959"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2266 13.3335C12.7066 13.2335 13.1599 13.0402 13.5332 12.7535C14.5732 11.9735 14.5732 10.6868 13.5332 9.90683C13.1666 9.62683 12.7199 9.44016 12.2466 9.3335"
        stroke="#595959"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RoleIcon;
