import React from "react";

const IconGoback = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.39355 17.3615H14.7269C17.9469 17.3615 20.5602 14.7482 20.5602 11.5282C20.5602 8.30816 17.9469 5.69482 14.7269 5.69482H1.89355"
        stroke="#333333"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.57846 8.61152L1.5918 5.62485L4.57846 2.63818"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconGoback;
    