import moment from 'moment';
import ActionType from './type';

export const setUserInfo = (data:any) => {
  return {
    type: ActionType.SET_USER,
    payload: data,
  };
};
export const logoutUser = () => {
  return {
    type: ActionType.LOGOUT,
  };
};


export const setDataDepartments = (data:any) => {
  return {
    type: ActionType.DEPARTMENT,
    payload: data,
  };
}

export const setDataPlaces = (data:any) => {
  return {
    type: ActionType.PLACE,
    payload: data,
  };
}

export const setDataBanner = (data:any) => {
  return {
    type: ActionType.BANNER,
    payload: data,
  };
}
export const setDataSetting = (data:any) => {
  return {
    type: ActionType.BANNER,
    payload: data,
  };
}
export const openModalPlace = (open:boolean,href:string) => {
  return {
    type: ActionType.CHOOSE_PLACE,
    payload: {modalPlace:{
      action:open ? open : false,
      href:href ? href : ''
    }},
  };
}

export const setActionType = (type:any) => {
  return {
    type: ActionType.ACTION_TYPE,
    payload: {actionType:type},
  };
}


export const clearLoading = (load:boolean) => {
  return {
    type: ActionType.TIME_LOADING,
    payload: {loading:load ? load : false},
  };
}


export const setDateStatical = (date:any) => {
  return {
    type: ActionType.DATE_STATICAL,
    payload:{dateStatical:date ? date : moment().toDate()}
  }
} 
